<template>
  <div>
    <v-row v-if="isManager">
      <v-col cols="12" md="6">
        <SalesmanSearch @input="setSalesman($event)" />
      </v-col>
      <v-col cols="12" md="6">
        <CompanySelect
          @input="select()"
          :item-value="(val) => val.id"
          v-model="searchParams.company_id"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <app-card-dashboard
          title="Vendas no Mês"
          icon=" mdi-cart-plus"
          iconColor="success"
        >
          <template v-slot:text>
            <h2>
              {{ $format.moneyBr(report.sale_total) }}
            </h2>
          </template>
        </app-card-dashboard>
      </v-col>
      <!-- <v-col>
        <app-card-dashboard
          title="Devoluções no Mês"
          icon=" mdi-cart-minus"
          iconColor="error"
        >
          <template v-slot:text>
            <h2>
              {{ $format.moneyBr(devolutionMonth.net_total_sum * -1) }}
            </h2>
          </template>
        </app-card-dashboard>
      </v-col> -->

      <v-col cols="12" md="6">
        <app-card-dashboard
          icon=" mdi-account-group"
          iconColor="secondary"
          title="Atendimentos"
        >
          <template v-slot:text>
            <h2>
              {{ $format.decimal(attendanceMonth.sales_count, 0) }}
            </h2>
          </template>
        </app-card-dashboard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title>Entregas Pendentes/Parcial </v-card-title>
          <v-card-subtitle>
            {{ sales.total }} registro(s) encontrado(s)
          </v-card-subtitle>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="sales.data"
              :server-items-length="-1"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              dense
              @click:row="handleSaleView($event)"
            >
              <template v-slot:[`item.customer`]="{ item }">
                <span v-if="!item.customer">Consumidor Final</span>
                <span v-else>{{ item.customer.name }}</span>
                <div class="pb-1">
                  <v-chip color="primary" x-small>
                    {{ item.operation.description }} #{{ item.number }}
                  </v-chip>
                  <small class="pl-2">
                    {{ $format.dateTimeBr(item.created_at) }}
                  </small>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ $format.dateTimeBr(item.created_at) }}
              </template>

              <template v-slot:[`item.delivery_status`]="{ item }">
                <v-menu bottom left class="mr-1">
                  <template v-slot:activator="{ on }">
                    <SaleDeliveryStatus
                      v-on="on"
                      :deliveryStatus="item.delivery_status"
                    />
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-if="canStoreDelivery(item)"
                      @click="scheduleDelivery(item.id)"
                      :disabled="item.delivery_status == 'delivered'"
                    >
                      <v-list-item-icon class="mr-2">
                        <app-icon>local_shipping</app-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Agendar Entregar
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        item.delivery_type == 'delivery' &&
                        $acl.can('deliveriesIndex')
                      "
                      @click="handleViewDeliveries(item.number)"
                    >
                      <v-list-item-icon class="mr-2">
                        <app-icon>travel_explore</app-icon>
                      </v-list-item-icon>
                      <v-list-item-content>Ver entregas</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <app-pagination @click="getSales($event)" :data="sales" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title>Vendas </v-card-title>
          <v-card-text>
            <app-charts :options="options" :series="series" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <SaleView ref="SaleView" @store="select()" />
  </div>
</template>

<script>
import SaleDeliveryStatus from "@/components/sales/sections/SaleDeliveryStatus.vue";
import SaleView from "@/components/sales/sections/SaleView.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect";

export default {
  components: {
    SaleDeliveryStatus,
    SaleView,
    SalesmanSearch,
    CompanySelect,
  },

  data() {
    return {
      report: {
        sales_report: [],
        attendance_report: [],
        devolution_report: [],
      },
      sales: {},
      headers: [
        { text: "Cliente", value: "customer" },

        {
          text: "Entrega",
          value: "delivery_status",
          align: "center",
          width: "10%",
        },
      ],
      searchParams: {
        delivery_status: ["not_delivered", "partial"],
        status: ["sale"],
        delivery_type: " delivery",
      },

      month: new Date().toISOString().substr(0, 7),
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.userData.user;
    },

    isManager() {
      return this.user.position == "gerente" || this.user.super_admin == 1;
    },

    salesMonth() {
      return (
        this.report.sales_report.find((item) => {
          return item.date_month === this.month;
        }) || 0
      );
    },
    devolutionMonth() {
      return (
        this.report.devolution_report.find((item) => {
          return item.date_month === this.month;
        }) || 0
      );
    },

    attendanceMonth() {
      return (
        this.report.attendance_report.find((item) => {
          return item.date_month === this.month;
        }) || 0
      );
    },

    options() {
      return {
        xaxis: {
          categories:
            this.report.sales_report.map((item) =>
              this.$format.dateBr(item.date_month, "MMM/y")
            ) || [],
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.$format.moneyBr(value);
            },
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return this.$format.moneyBr(value);
            },
          },
          enabled: true,
        },
      };
    },
    series() {
      return [
        {
          name: "Vendas",
          data:
            this.report.sales_report.map((item) => item.net_total_sum) || [],
        },
        {
          name: "Devoluções",
          data:
            this.report.devolution_report.map((item) => item.net_total_sum) ||
            [],
        },
      ];
    },
  },

  created() {
    if (!this.isManager) {
      this.setSalesman(this.user);
    }
    if (this.isManager) {
      this.select();
    }
  },

  methods: {
    async select() {
      this.$http
        .index("dashboard/sales", this.searchParams)
        .then((response) => {
          this.report = response;
          this.getSales();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSales(page = 1) {
      this.searchParams.page = page;

      this.$loading.start();
      this.$http
        .index("sale/sale", this.searchParams)
        .then((response) => {
          this.sales = response.sales;
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },
    handleSaleView(sale) {
      this.$refs.SaleView.open(sale.id);
    },

    setSalesman(salesman) {
      this.searchParams.salesman_id = salesman.id;

      this.select();
    },

    canStoreDelivery(sale) {
      if (sale.status == "sale" && sale.delivery_type !== "no_delivery") {
        return true && this.$acl.can("deliveryCreateEdit");
      }
      return false;
    },
    scheduleDelivery(sale_id) {
      window.open(`/entrega/novo/${sale_id}`);
    },
    handleViewDeliveries(sale_number) {
      window.open(
        `/vendas/visualizar-entrega/?sale_number=${sale_number}`,
        "_blank"
      );
    },
  },
};
</script>

<style>
</style>