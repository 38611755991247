var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isManager)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('SalesmanSearch',{on:{"input":function($event){return _vm.setSalesman($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('CompanySelect',{attrs:{"item-value":function (val) { return val.id; }},on:{"input":function($event){return _vm.select()}},model:{value:(_vm.searchParams.company_id),callback:function ($$v) {_vm.$set(_vm.searchParams, "company_id", $$v)},expression:"searchParams.company_id"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('app-card-dashboard',{attrs:{"title":"Vendas no Mês","icon":" mdi-cart-plus","iconColor":"success"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('h2',[_vm._v(" "+_vm._s(_vm.$format.moneyBr(_vm.report.sale_total))+" ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('app-card-dashboard',{attrs:{"icon":" mdi-account-group","iconColor":"secondary","title":"Atendimentos"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('h2',[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.attendanceMonth.sales_count, 0))+" ")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Entregas Pendentes/Parcial ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.sales.total)+" registro(s) encontrado(s) ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sales.data,"server-items-length":-1,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},on:{"click:row":function($event){return _vm.handleSaleView($event)}},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!item.customer)?_c('span',[_vm._v("Consumidor Final")]):_c('span',[_vm._v(_vm._s(item.customer.name))]),_c('div',{staticClass:"pb-1"},[_c('v-chip',{attrs:{"color":"primary","x-small":""}},[_vm._v(" "+_vm._s(item.operation.description)+" #"+_vm._s(item.number)+" ")]),_c('small',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.created_at))+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.created_at))+" ")]}},{key:"item.delivery_status",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{staticClass:"mr-1",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('SaleDeliveryStatus',_vm._g({attrs:{"deliveryStatus":item.delivery_status}},on))]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.canStoreDelivery(item))?_c('v-list-item',{attrs:{"disabled":item.delivery_status == 'delivered'},on:{"click":function($event){return _vm.scheduleDelivery(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('app-icon',[_vm._v("local_shipping")])],1),_c('v-list-item-content',[_vm._v(" Agendar Entregar ")])],1):_vm._e(),(
                      item.delivery_type == 'delivery' &&
                      _vm.$acl.can('deliveriesIndex')
                    )?_c('v-list-item',{on:{"click":function($event){return _vm.handleViewDeliveries(item.number)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('app-icon',[_vm._v("travel_explore")])],1),_c('v-list-item-content',[_vm._v("Ver entregas")])],1):_vm._e()],1)],1)]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.sales},on:{"click":function($event){return _vm.getSales($event)}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Vendas ")]),_c('v-card-text',[_c('app-charts',{attrs:{"options":_vm.options,"series":_vm.series}})],1)],1)],1)],1),_c('SaleView',{ref:"SaleView",on:{"store":function($event){return _vm.select()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }