<template>
  <div>
    <app-icon
      v-on="$listeners"
      :color="statuses[deliveryStatus].color"
      small
      class="mr-1"
      >{{ statuses[deliveryStatus].icon }}</app-icon
    >
    <b v-on="$listeners" :class="[`${statuses[deliveryStatus].color}--text`]">{{
      statuses[deliveryStatus].text
    }}</b>
  </div>
</template>

<script>
export default {
  props: {
    deliveryStatus: {},
  },

  data: () => ({
    statuses: {
      not_delivered: {
        icon: "close",
        color: "disabled",
        text: "Sem Entrega",
      },
      partial: {
        icon: "schedule",
        color: "warning",
        text: "Parcial",
      },
      delivered: {
        icon: "task_alt",
        color: "success",
        text: "Entregue",
      },
    },
  }),
};
</script>

<style>
</style>